import React from "react";
import Route from "./Routes/routeIndex";
import "./App.css";

//import style
import "./assets/css/pe-icon-7.css";
import "./assets/css/materialdesignicons.min.css";
import "./assets/scss/theme.scss"; 
import 'react-notifications/lib/notifications.css';
import {NotificationContainer} from 'react-notifications';


function App() { 
 // console.log(process.env.REACT_APP_SMSMEDIA_API_KEY);
  return (
    <React.Fragment>
      <Route />
      <NotificationContainer/>
    </React.Fragment>

  );
}

export default App;
