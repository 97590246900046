import React from "react";
import { Route, Routes } from "react-router-dom"; 
import Layout from '../pages/Layout';
import About from '../component/About'; 
import Section from "../pages/Section";
import Modules from "../component/Modules";
import Pricing from "../component/Pricing"; 
import Blog from "../component/Blog";
import Contact from "../component/Contact";  
import ScrollToTop from './ScrollToTop'; 
import BlogPost from "../component/BlogPost" ;
import Page from "../component/Page" ;

const Index = () => {
    return (
        <React.Fragment>           
            <Routes>
                            <Route path='/' element={<><Layout /><ScrollToTop /></>}>
                            <Route index element={<Section />} />                            
                                <Route path="about-us" element={<About />} />
                                <Route path="modules" element={<Modules />} />
                                <Route path="pricing" element={<Pricing />} /> 
                                <Route path="blog" element={<Blog />} />                               
                                <Route path="contact-us" element={<Contact />} /> 
                                <Route path="/blog/:slug" element={<BlogPost />} /> 
                                <Route path="/page/:slug" element={<Page />} /> 
                                </Route>
                                </Routes>
        </React.Fragment>
    )
}

export default Index;