import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import  aboutData  from "../common/data/about";

//import images
import about from '../assets/images/about-edutron-school-erp.webp'; 

const About = (props) => {
    return (
        <React.Fragment> 
            <section className={props.isPrimary ? "section bg-about" : "section bg-about bg-light-about bg-light"} id="about">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="title-heading mb-5">
                                <h3 className={props.isPrimary ? "text-white mb-1 fw-light text-uppercase" : "text-dark mb-1 fw-light text-uppercase"}>About Us</h3>
                                <div className="title-border-simple position-relative"></div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        {aboutData.map((item, key) => (
                            <Col key={key} lg={4}>
                                <div className={props.isPrimary ? "about-box text-center p-3" : "about-box about-light text-center p-3"}>
                                    <div className="about-icon mb-4">
                                        <i className={item.icon}></i>
                                    </div>
                                    <h4 className="fw-light"><Link to="#" className={props.isPrimary ? "text-white" : "text-dark"}>{item.title}</Link></h4>
                                    <p className={props.isPrimary ? "text-white-50 f-14" : "text-muted f-14"}>{item.description}</p>
                                </div>
                            </Col>
                        ))}
                    </Row>

                    <Row className="align-items-center mt-5">
                        <Col md={6}>
                            <div className="about-desc">
                                <h3 className={props.isPrimary ? "text-white mb-3 fw-light" : "text-dark mb-3 fw-light"}>About Edutron School ERP</h3>
                                <p className={props.isPrimary ? "text-white-50 f-15" : "text-muted f-15"}>Edutron School ERP is a comprehensive software solution designed for schools to manage their administrative tasks efficiently. It offers features such as student tracking, fee management, grading, attendance tracking, and more. The software's user-friendly interface, real-time notification feature, and efficient white-labeling options make it a popular choice among schools. The software's goal is to simplify administrative tasks, streamline communication, and increase overall operational efficiency. Additionally, Edutron School ERP's exceptional customer support ensures that users have all the resources they need to make the most of the software.</p>
                                <div className="about-by">
                                    <p className="fw-light mb-0"><Link to={`/page/edutron-school-erp-about-us`} className={props.isPrimary ? "text-white" : "text-dark"}>
                                        <span className="text-custom">learn more... </span></Link></p>
                                </div>
                            </div>
                        </Col>

                        <Col md={6}>
                            <div className={props.isPrimary ? "about-img position-relative p-4" : "about-img light-img position-relative p-4"}>
                                <img src={about} alt="edutron school erp about us" className="img-fluid mx-auto d-block" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>           
        </React.Fragment>
        
    );
};
export default About;
