import React from "react";
import Navbar from "../component/Navbar";
import Footer from "../component/Footer/Footer";
import { Outlet } from "react-router-dom";

const Layout4 = () => {
  return (
    <React.Fragment>
      <Navbar isDark="navbar-dark"/>
      <Outlet />
      <Footer />
    </React.Fragment>
  );
};
export default Layout4;
