//import Images
import img1 from "../../assets/images/clients/img-1.webp";
import img2 from "../../assets/images/clients/img-2.webp";
import img3 from "../../assets/images/clients/img-3.webp";
import img4 from "../../assets/images/clients/img-4.webp";
import img5 from "../../assets/images/clients/img-5.webp";
import img6 from "../../assets/images/clients/img-6.webp";

const clientsData = [
    {
        id: 1,
        title: "Shyam Singh",
        textContent: "I have been using the Edutron School ERP for the past year and it has revolutionized the way our school operates. From managing student records to creating schedules and generating reports, Edutron has made everything so much easier and efficient. The user-friendly interface and customizable features have allowed us to streamline our processes and stay organized. The support team is also excellent, they have always been quick to respond and provide solutions to any issues we have encountered. I highly recommend Edutron School ERP to any educational institution looking to improve their operations.",
        img: img1,
        author: "Adarsh Vikas Vidyalay",
        category: "Director",
    },
    {
        id: 2,
        title: "Rohit Singh",
        textContent: "As a user of the Edutron School ERP, I am extremely impressed with its performance. The software has made managing our school's administrative tasks a breeze. From tracking student attendance to handling fees and grades, everything is now just a few clicks away. The user-friendly interface and excellent customer support make Edutron a complete solution for any school looking to improve their operational efficiency. I highly recommend Edutron to any educational institution in need of a comprehensive and reliable ERP solution.",
        img: img2,
        author: "ZHI School",
        category: "Principle",
    },
    {
        id: 3,
        title: "Alok Choudhary",
        textContent: "I have been using Edutron School ERP for several months now and it has truly been a game changer for our school. The software streamlines administrative tasks and provides a centralized platform for managing student records, assignments, and assessments. The user-friendly interface makes it easy for teachers and administrators to use, and the customer support team is always available to help with any questions or issues. Overall, I highly recommend Edutron School ERP to any school looking for a comprehensive and reliable solution to manage their administrative tasks.",
        img: img3,
        author: "St. Georgia School",
        category: "Principle",
    },
    {
        id: 4,
        title: "Radheshyam Singh",
        textContent: "As a user of Edutron School ERP, I highly recommend it to any school looking for a comprehensive and efficient solution for managing their administrative tasks. The software has made tracking student attendance, handling fees, and grading assignments a breeze. The user-friendly interface is easy to navigate and the customer support is exceptional. The Edutron team is always available to answer any questions or concerns I have. Overall, Edutron has greatly improved our school's operational efficiency and I am confident that it will do the same for others.",
        img: img4,
        author: "St. Josheph School",
        category: "Principle",
    }, 
];
export default clientsData;