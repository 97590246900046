const aboutData = [
    {
        id: 1,
        icon: "mdi mdi-web",
        title: "White Labeling",
        description : "Edutron School ERP's white labeling feature allows schools to customize the software with their own logo, brand colors, and domain name to create a more personalized experience. This helps schools maintain a consistent brand image and increase recognition among students and staff."
    },
    {
        id: 2,
        icon: "mdi mdi-book",
        title: "Easy to Use",
        description : "Edutron School ERP offers a user-friendly interface and easy to use features, making it simple for administrators, teachers, and staff to manage their day-to-day tasks. The software offers features such as student tracking, fee management, and grading, all accessible through a clear and intuitive dashboard. This helps streamline school operations and increase productivity."
    },
    {
        id: 3,
        icon: "mdi mdi-alert",
        title: "Notification",
        description : "Edutron School ERP's notification feature keeps users informed and up to date with important information and events. The software sends real-time notifications to administrators, teachers, and students via email or SMS, ensuring that everyone stays informed about important updates and deadlines. This helps streamline communication, reduce the risk of missed deadlines, and increase overall efficiency."
    },
]; 
export default aboutData;