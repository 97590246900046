const footerData = [
    {
        id: 1,
        title: "company",
        childItem: [ 
            { subItem: "Job Opening"  ,to:'/contact-us'}
        ],
    },
    {
        id: 2,
        title: "support",
        childItem: [
            { subItem: "Get Started"  ,to:'/contact-us'},
            { subItem: "Blog",to:'/blog' },
            { subItem: "Knowledge Base"  ,to:'/blog'},
        ],
    },
    {
        id: 3,
        title: "Legal",
        childItem: [
            { subItem: "Terms & Conditions"  ,to:'/page/edutron-school-erp-terms-conditions'},
            { subItem: "Privacy Policy"  ,to:"/page/privacy-policy" }, 
            { subItem: "Pricing"  ,to:'/pricing'},
        ]
    }
];
export default footerData;