import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Button, Stack, TextField ,Box} from '@mui/material';
import { Link } from 'react-router-dom'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';    
// Modal Video
import ModalVideo from "react-modal-video";
import Alert from '@mui/material/Alert';
import api from '../api';

///import images
import home from '../assets/images/home-img-edutron-school-erp.webp';
import { NotificationManager} from 'react-notifications';
import About from '../component/About';
import Features from '../component/Features';
import Clients from '../component/Clients';
const Section = () => {
    const [open, setOpen] = useState(false);
    const [openOTP,setOpenOTP]=useState(false);
    const [mobile,setMobile]=useState('');
    const [openRegister,setOpenRegister] = useState(false);
    const [verifyOtp,setVerifyOtp]=useState({
        otp:'',
        uid:''
    });
    const [enqForm,setEnqForm]=useState({
        schoolName: "",
        contactPerson:"",
        mobile:"",
        email: "",
        query: "",   
        consentReceived : true,
        source : "verification_form"
    });
    const [loading,setLoading]=useState(false);

    const sendOTP = async () =>{
        const reg = new RegExp("[6-9][0-9]{9}"); 
        if (reg.test(mobile) == false){     
         NotificationManager.error('Invalid mobile number entered !');
         return;
        } 
        let smstext="Dear Concern, {otp} is your OTP. Patron Services";
        setLoading(true);
        await api.get('sms/send-otp-api', {
            params: {
                apikey : "nGGP0hz6QqEuc26WuQkZVxeIxTvqtzrLXxxPr2JMw4R",
                senderid : "PATSER",
                mobile : mobile,
                smstext : smstext,
                serviceid:16,
                peid:"1201159825774033626",
                templateid:"1207161845816576472"
            }
            })
        .then((response) => {
            setVerifyOtp({...verifyOtp,uid:response.data});
            setOpenOTP(true);
            NotificationManager.success("OTP Sent !");
            })
            .catch(error => {
            }).finally(()=>{
                setLoading(false);
            }) 
    }

    const submitRequest =async () =>{
        if (enqForm.schoolName.length == 0){
            NotificationManager.error("School name required !");
            return;
        }
        enqForm.mobile = mobile;
        //console.log(enqForm);
        setLoading(true);
        await api
        .post('website/edutron-school/post-enquiry', JSON.stringify(enqForm))
        .then((response) => { 
            setOpenOTP(false);
            setOpenRegister(false);
            NotificationManager.success('Thank you for showing interest in Edutron School ERP.');  
        })
        .catch((error) => { 
        })
        .finally(() => { 
            setLoading(false);
        }); 
    }

    const onVerifyOTP = async () =>{
        console.log(verifyOtp); 
        setLoading(true);
        await api.get('sms/verify-otp-api', {
            params: verifyOtp
            })
        .then((response) => {
            setOpenRegister(true);
            NotificationManager.success("Verified !");
            })
            .catch(error => {
            }).finally(()=>{
                setLoading(false);
            })
        
    }

    return (
        <React.Fragment> 
            <section className="section home-4-bg" id="home">
                <div className="home-center">
                    <div className="home-desc-center">
                        <Container>
                            <Row className="align-items-center">
                                <Col lg={6} className="position-static">
                                    <div className="mt-40 home-4-content">
                                        <h1 className="text-white fw-normal home-2-title mb-0">Are you looking for School ERP?</h1>
                                        <p className="text-white-70 mt-4 f-15 mb-0">We have the complete solution for your entire school need.</p>
                                         
                                       {!openOTP && <Stack direction={'row'} spacing={1} marginY={4}>
                                            <TextField type='tel' 
                                            label="Enter mobile" onChange={(e) => setMobile(e.target.value)} 
                                            value={mobile}
                                            inputProps={{ maxLength: 10 }} 
                                            />
                                            <Button variant='contained' size='medium' sx={{height: 55}} onClick={sendOTP} disabled={loading}>{loading ? 'sending...' : 'Register'}</Button>   
                                            </Stack>
                                        } 
                                        {openOTP && <Stack direction={'row'} spacing={1} marginY={4}>
                                            <TextField type='tel' 
                                            label="Enter OTP" onChange={(e) => setVerifyOtp({...verifyOtp,otp:e.target.value})} 
                                            value={verifyOtp.otp}
                                            inputProps={{ maxLength: 4 }} 
                                            required={true}
                                            autoFocus />
                                            <Button variant='contained' size='medium' sx={{height: 55}} onClick={onVerifyOTP} disabled={loading}>{loading ? 'matching...' : 'Verify OTP'}</Button>   
                                        </Stack> }
                                    </div>
                                    {/* <div className="video-button">
                                        <p className="play-shadow mb-0"><Link
                                            onClick={() => setOpen(true)}
                                            to="#" className="video-play-icon">
                                            <i className="mdi mdi-play play-icon-circle "></i></Link>
                                        </p>
                                    </div> */}
                                    <div className="modal fade bd-example-modal-lg" id="watchvideomodal" data-keyboard="false"
                                        tabIndex="-1" aria-hidden="true">
                                        <div className="modal-dialog modal-dialog-centered modal-dialog modal-lg">
                                            <div className="modal-content home-modal">
                                                <div className="modal-header border-0">
                                                    <button type="button" className="btn-close"
                                                        aria-label="Close"></button>
                                                </div>
                                                <video id="VisaChipCardVideo" className="video-box" controls>
                                                    <source src="https://www.w3schools.com/html/mov_bbb.mp4" type="video/mp4" />

                                                </video>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6} className="position-static">
                                    <div className="mt-40 home-4-img position-relative ps-5">
                                        <img src={home} loading='lazy' alt="edutron school erp home page images" className="img-fluid mx-auto d-block" />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    {/* <ModalVideo
                        channel="vimeo"
                        isOpen={open}
                        videoId="287684225"
                        autoplay
                        onClose={() => setOpen(false)}
                    /> */}
                </div>
            </section>
            <About />
            <Features />
            <Clients />
            <Dialog open={openRegister} onClose={() => setOpenRegister(false)}> 
            <DialogTitle>Request Demo</DialogTitle> 
        <DialogContent> 
            
          <TextField
            autoFocus  
            label="School Name"
            type="text"
            fullWidth
            variant="outlined"
            marginY={5}
            sx={{marginTop:2}}
            value={enqForm.schoolName}
            onChange={(e)=> setEnqForm({...enqForm,schoolName:e.target.value})}
          />
          <TextField 
            label="Your Name"
            type="text"
            fullWidth
            variant="outlined"
            sx={{marginTop:2}}
            value={enqForm.contactPerson}
            onChange={(e)=> setEnqForm({...enqForm,contactPerson:e.target.value})}
          /> 
          <TextField 
            label="Email"
            type="email"
            fullWidth
            variant="outlined"
            sx={{marginTop:2}}
            value={enqForm.email}
            onChange={(e)=> setEnqForm({...enqForm,email:e.target.value})}
          />
          <TextField  
            label="Message"
            type="text"
            fullWidth
            variant="outlined"
            multiline
            rows={4}
            sx={{marginTop:2}}
            value={enqForm.query}
            onChange={(e)=> setEnqForm({...enqForm,query:e.target.value})}
          /> 
        </DialogContent>
        <DialogActions> 
          <Button variant='contained' sx={{marginRight:2}} onClick={() => submitRequest()} disabled={loading}>{loading ? 'sending request...' : 'Submit'}</Button>
        </DialogActions>
      </Dialog>
        </React.Fragment>
    );
};
export default Section;
