import React, { useState,useEffect } from 'react';
 import {Link} from 'react-router-dom';
import { styled  } from '@mui/system';
import {  Grid, Typography, Button,Pagination } from '@mui/material';
import { Container,Row, Col } from 'reactstrap';  
import api from '../api';
import moment from 'moment/moment';
import { Helmet } from 'react-helmet';
const PAGE_SIZE = 6;

function BlogList() { 
    const [loading,setLoading]=useState(false);
    const [posts,setPosts] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * PAGE_SIZE;
  const endIndex = startIndex + PAGE_SIZE;
  const visiblePosts = posts && posts.slice(startIndex, endIndex);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
  (async () => { 
    setLoading(true);
    await api.get('website/blog?PageNumber=1&PageSize=100', {
        params: {
            websitekey: 'edutronschoolin'
        }
    })
        .then((response) => {
            setPosts(response.data.data);
        })
        .catch(error => {             
        }).finally(() => { 
            setLoading(false);
        });
    })();
  },[])



  return (
    <React.Fragment>
           <Helmet re>
        <title>Edutron School Blog</title>
        <meta name="description" content=" Edutron School's blog provides valuable insights, tips, and news on school management and education technology. Our blog covers a wide range of topics, including fee management, student information management, exam management, library management, HR and payroll management, and more. Stay up-to-date with the latest trends and best practices in school management and education technology by following our blog. Subscribe today to receive regular updates." />
        <meta name="title" content="Edutron School Blog - Insights, Tips, and News on School Management and Education Technology" />
      </Helmet>
    <section className="section" id="blog"  style={{marginTop:40}}>
        <Container> 
             <Row>
                        <Col lg={12}>
                            <div className="title-heading mb-5">
                                <h3 className="text-dark mb-1 fw-light text-uppercase">Blog</h3>
                                <div className="title-border-simple position-relative"></div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                    <Col lg={12} sm={12}>
                        {loading && <strong>loading blogs...</strong>}
      <Grid container>
        {visiblePosts && visiblePosts.map((item) => ( 
        <Col key={item.id} lg={4} sm={12} md={4} style={{padding:10}}>
        <div className="blog position-relative">
            <div className="blog-img position-relative mt-4">
                <div className="blog-date">
                    <p className="mb-0 fw-light text-white f-15">{moment(item.creationTime).format("DD-MMM-YYYY")}</p>
                </div>
                <img src={item.image} alt="school erp blog" className="img-fluid mx-auto d-block rounded" />
            </div>
            <div className="position-relative">
                <div className="blog-content text-center bg-white p-4">
                    <p className="text-uppercase f-13 mb-2 text-muted">{item.category}</p>
                    <h5 className="fw-normal f-18">
                        <Link to={`/blog/${item.slug}`} className="text-dark">{item.title}</Link>
                    </h5>
                    <p className="text-muted f-14">{item.initialContent}</p>
                    <div className="read-more">
                        <Link to={`/blog/${item.slug}`} className=" text-primary f-15">Read more<i
                            className="mdi mdi-arrow-right"></i></Link>
                    </div>
                </div>
            </div>
        </div>
    </Col>
        ))}
      </Grid>
      </Col>
      </Row>
      <Row>
      <Col lg={12}>
      <Grid container justifyContent="center">
      {posts && <Pagination count={Math.ceil(posts.length / PAGE_SIZE)} page={currentPage} onChange={handlePageChange} />}  
      </Grid>
      </Col>
      </Row>
      </Container>
    </section>
    </React.Fragment>
  );
}

export default BlogList;
