import React ,{useState,useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';  
import api from '../api';
import { Helmet } from 'react-helmet';
function BlogPost() {
  const [loading,setLoading]=useState(false);
  const { slug } = useParams();
  const [post, setPost] = useState([]);
 

  useEffect(() => {
    (async () => { 
      setLoading(true);
      await api.get('website/blog/get-blog-by-slug', {
          params: {
              websitekey: 'edutronschoolin',
              slug:slug
          }
      })
          .then((response) => {
              setPost(response.data);
          })
          .catch(error => {             
          }).finally(() => { 
            setLoading(false);
          });
      })();
    },[slug])

    const canonicalUrl = `https://edutronschool.in/blog/${slug}`;


  return (
    <React.Fragment> 
        <Helmet>
        <title>{post.pageTitle}</title>
        <meta name="description" content={post.metaDescription} />
        <meta name="title" content={post.metaTitle} />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
    <section className={"section bg-about bg-light-about bg-light"} id="about" style={{marginTop:50}}>
        <Container>
        <Row>
        {loading && <strong>loading blog...</strong>}
                        <Col lg={12}>
                            <div className="title-heading mb-5">
                                <h3 className={"text-dark mb-1 fw-light text-uppercase"}>{post.title}</h3>
                                <div className="title-border-simple position-relative"></div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-2"> 
                        <Col md={12}>
                            <div className="about-desc">  
                            <div dangerouslySetInnerHTML={{ __html: post.blogContent }} />
                                
                            </div>
                        </Col>
                    </Row> 
    </Container>
    </section>
    </React.Fragment>
  );
}

export default BlogPost;
