const pricingData = [
    {
        id: 1,
        title: "",
        price: "5000 /Year",
        isChilItem: [
            {id: 1, subItem: "Upto: 500 student(s)"},
            {id: 2, subItem: "Mobile App (student): Yes"},
            {id: 3, subItem: "Mobile App (Teacher): Yes"},
            {id: 4, subItem: "White Labeling : No"},
            {id: 5, subItem: "Support: Yes"},
            {id: 5, subItem: "One-time (1st Year): 15000"},
            {id: 5, subItem: "Custom App (Student): No"},
        ],
    },
    {
        id: 2,
        title: "Popular",
        price: "10000 /Year",
        isActive: true,
        isBtnActive: true,
        isChilItem: [
            {id: 1, subItem: "Upto: 1000 student(s)"},
            {id: 2, subItem: "Mobile App (student): Yes"},
            {id: 3, subItem: "Mobile App (Teacher): Yes"},
            {id: 4, subItem: "White Labeling : No"},
            {id: 5, subItem: "Support: Yes"},
            {id: 5, subItem: "One-time (1st Year): 20000"},
            {id: 5, subItem: "Custom App (Student): No"},
        ],
    },
    {
        id: 3,
        title: "Advance",
        price: "15000 /Year",
        isChilItem: [
            {id: 1, subItem: "Upto: 1500 student(s)"},
            {id: 2, subItem: "Mobile App (student): Yes"},
            {id: 3, subItem: "Mobile App (Teacher): Yes"},
            {id: 4, subItem: "White Labeling : No"},
            {id: 5, subItem: "Support: Yes"},
            {id: 5, subItem: "One-time (1st Year): 30000"},
            {id: 5, subItem: "Custom App (Student): No"},
        ],
    },
    {
        id: 4,
        title: "Premium",
        price: "20000 /Year",
        isChilItem: [
            {id: 1, subItem: "Upto: 2000 student(s)"},
            {id: 2, subItem: "Mobile App (student): Yes"},
            {id: 3, subItem: "Mobile App (Teacher): Yes"},
            {id: 4, subItem: "White Labeling : No"},
            {id: 5, subItem: "Support: Yes"},
            {id: 5, subItem: "One-time (1st Year): 40000"},
            {id: 5, subItem: "Custom App (Student): Yes"},
        ],
    },
];
export default pricingData;