import React,{useState} from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Input, Form, FormFeedback } from 'reactstrap';
import api from '../api';

//import validation
import { useFormik } from "formik";
import * as Yup from 'yup';
import { NotificationManager } from 'react-notifications';
import { Helmet } from 'react-helmet';

const Contact = () => {
    const [loading,setLoading]=useState(false);

    const validation = useFormik({
        initialValues: {
            schoolName: "",
            mobile:"",
            email: "",
            query: "",   
            consentReceived : true,
            source : "enquiry_form"
        },
        validationSchema: Yup.object({ 
            schoolName: Yup.string().required("*Please enter School Name*"),
            mobile: Yup.string().matches(/^[6-9]\d{9}$/, {message: "Please enter valid mobile number.", excludeEmptyString: false}).required("*Please enter Mobile*"),
            email: Yup.string().required("*Please enter Email*"),          
        }),
        onSubmit: async (values) => { 
            setLoading(true);
            await api
            .post('website/edutron-school/post-enquiry', JSON.stringify(values))
            .then((response) => { 
                    NotificationManager.success('Thank you for showing interest in Edutron School ERP.');  
            })
            .catch((error) => {                 
            })
            .finally(() => { 
                setLoading(false);
            });
        },
    })
    return (
        <React.Fragment>
             <Helmet>
        <title>Contact Edutron School</title>
        <meta name="description" content="If you have any questions or inquiries about our ERP solutions for schools, please don't hesitate to contact us. Our expert team is always here to help you find the right solutions for your school's needs. Whether you need assistance with fee management, student management, exam management, library management, HR and payroll management, or any other aspect of school administration, we're here to help. Contact us today to learn more about how we can help your school succeed." />
        <meta name="title" content="Contact Edutron School - Get in Touch with Our Expert Team" />
      </Helmet>
            <section className="section bg-light" id="contact"  style={{marginTop:40}}>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="title-heading mb-5">
                                <h3 className="text-dark mb-1 fw-light text-uppercase">Get in touch</h3>
                                <div className="title-border-simple position-relative"></div>
                            </div>
                        </Col>

                    </Row>

                    <Row>
                        <Col lg={12}>
                            <div className="contact-box p-5">
                                <Row>
                                    <Col lg={8} md={6}>
                                        <div className="custom-form p-3">
                                            <Form method="post" name="myForm" onSubmit={validation.handleSubmit}>
                                                <p id="error-msg"></p>
                                                <div id="simple-msg"></div>
                                                <Row>
                                                    <Col lg={12}>
                                                        <div className="mb-3 app-label">
                                                            <Input
                                                                name="schoolName"
                                                                id="schoolName"
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="School Name"
                                                                value={validation.values.schoolName || ""}
                                                                onBlur={validation.handleChange}
                                                                onChange={validation.handleChange}
                                                                invalid={validation.errors.schoolName && validation.touched.schoolName ? true : false}
                                                                maxLength={100}
                                                            />
                                                            {validation.errors.schoolName && validation.touched.schoolName ? (
                                                                <FormFeedback type="invalid">{validation.errors.schoolName}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>

                                                    <Col lg={6}>
                                                        <div className="mb-3 app-label">
                                                            <Input
                                                                name="mobile"
                                                                id="mobile"
                                                                type="tel"
                                                                className="form-control"
                                                                placeholder="Mobile"
                                                                value={validation.values.mobile || ""}
                                                                onBlur={validation.handleChange}
                                                                onChange={validation.handleChange}
                                                                invalid={validation.errors.mobile && validation.touched.mobile ? true : false}
                                                                maxLength={10}
                                                            />
                                                            {validation.errors.mobile && validation.touched.mobile ? (
                                                                <FormFeedback type="invalid">{validation.errors.mobile}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>

                                                    <Col lg={6}>
                                                        <div className="mb-3 app-label">
                                                            <Input
                                                                name="email"
                                                                id="email"
                                                                type="email"
                                                                className="form-control"
                                                                placeholder="Email"
                                                                value={validation.values.email || ""}
                                                                onBlur={validation.handleChange}
                                                                onChange={validation.handleChange}
                                                                invalid={validation.errors.email && validation.touched.email ? true : false}
                                                                maxLength={50}
                                                            />
                                                            {validation.errors.email && validation.touched.email ? (
                                                                <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col> 

                                                    <Col lg={12}>
                                                        <div className="mb-3 app-label">
                                                            <textarea name="query" id="query" rows="5"   maxLength={1000}
                                                              onChange={validation.handleChange}
                                                                className="form-control" placeholder="Message" />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={12}>
                                                        <button type="submit" id="submit" name="send"
                                                            className="submitBnt btn btn-custom" value="Send Message" disabled={loading}>{loading ? 'sending...':'Send Message'}</button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </div>
                                    </Col>

                                    <Col lg={4} md={6}>
                                        <div className="contact-cantent p-3">
                                            <div className="contact-details">
                                                <div className="float-start contact-icon me-3 mt-2">
                                                    <i className="mdi mdi-headphones text-muted h5"></i>
                                                </div>
                                                <div className="app-contact-desc text-muted pt-1">
                                                    <p className="mb-0 info-title f-13">Sales : </p>
                                                    <p className="mb-0 f-13">+91 9304815125</p>
                                                </div>
                                            </div>

                                            <div className="contact-details">
                                                <div className="float-start contact-icon me-3 mt-2">
                                                    <i className="mdi mdi-account text-muted h5"></i>
                                                </div>
                                                <div className="app-contact-desc text-muted pt-1">
                                                    <p className="mb-0 info-title f-13">Support : </p>
                                                    <p className="mb-0 f-13">+91 7079 111 222</p>
                                                </div>
                                            </div>

                                            <div className="contact-details mt-2">
                                                <div className="float-start contact-icon me-3 mt-2">
                                                    <i className="mdi mdi-email-outline text-muted h5"></i>
                                                </div>
                                                <div className="app-contact-desc text-muted pt-1">
                                                    <p className="mb-0 info-title f-13">Email :</p>
                                                    <p className="mb-0 f-13"><Link to="/" className="text-muted">aman@patroninfotech.com</Link>
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="contact-details mt-2">
                                                <div className="float-start contact-icon me-3 mt-2">
                                                    <i className="mdi mdi-map-marker text-muted h5"></i>
                                                </div>
                                                <div className="app-contact-desc text-muted pt-1">
                                                    <p className="mb-0 info-title f-13">Location :</p>
                                                    <p className="mb-0 f-13"><Link to="/" className="text-muted">101,Sujyoti Mention,Gola Road,Patna-801503 (Bihar), India</Link></p>
                                                </div>
                                            </div>

                                            <div className="follow mt-4">
                                                <h4 className="text-dark mb-3">Follow</h4>
                                                <ul className="follow-icon list-inline mt-32 mb-0">
                                                    <li className="list-inline-item f-15">  
                                                            <a href='https://www.facebook.com/EdutronSoftware' target={'_blank'} className="social-icon text-muted">
                                                            <i className="mdi mdi-facebook"></i>
                                                            </a>
                                                    </li>
                                                    <li className="list-inline-item f-15">
                                                          <a href='https://twitter.com/EdutronSoftware' target={'_blank'} className="social-icon text-muted">
                                                            <i className="mdi mdi-twitter"></i>
                                                        </a>
                                                    </li>
                                                     <li className="list-inline-item f-15">
                                                     <a href='https://www.youtube.com/@edutronerp-schoolsoftware7474' target={'_blank'} className="social-icon text-muted">
                                                            <i className="mdi mdi-youtube"></i>
                                                        </a>
                                                            </li>  
                                                    <li className="list-inline-item f-15">
                                                        <a href='https://www.linkedin.com/company/edutron-school-erp/' target={'_blank'} className="social-icon text-muted">
                                                            <i className="mdi mdi-linkedin"></i>
                                                        </a>
                                                    </li>
                                                    <li className="list-inline-item f-15">
                                                          <a href='https://wa.me/message/M7FW76GWT45YE1' target={'_blank'} className="social-icon text-muted">
                                                            <i className="mdi mdi-whatsapp"></i>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    )
}
export default Contact;
