import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap'; 
import Contact from './Contact';
import { Helmet } from 'react-helmet';

const Services = () => {   
    return (
        <React.Fragment>
               <Helmet>
        <title>Edutron School ERP Modules</title>
        <meta name="description" content="Edutron School's ERP Modules offer a comprehensive suite of school management solutions designed to meet the unique needs of schools. Our modules include fee management, student management, exam management, library management, HR and payroll management, and more. With our user-friendly interface and advanced features, our modules make it easy for schools to manage all aspects of their operations in one place. Contact us today to learn more about our modules and how they can benefit your school." />
        <meta name="title" content='Edutron School ERP Modules - A Comprehensive Suite of School Management Solutions' />
      </Helmet>
            <section className="section" id="services" style={{marginTop:40}}>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="title-heading mb-5">
                                <h3 className="text-dark mb-1 fw-light text-uppercase">Modules</h3>
                                <div className="title-border-simple position-relative"></div>
                            </div>
                        </Col>
                    </Row> 

                    <Row>
                    <Col lg={4} md={6}>
                    <div className="service-box rounded mt-4 p-4">
                                    <div className="service-icon mb-3">
                                        <i className='mdi mdi-bus'></i>
                                    </div>
                                    <div className="services-desc">
                                        <div className="service-title mb-2 position-relative">
                                            <h5 className="fw-normal mb-3"><Link to={`/page/edutron-school-erp-transport`}  className="text-dark">
                                                Transport</Link></h5>
                                        </div>
                                        <p className="text-muted mb-3 f-14">Transport module efficiently manages school transportation, routes, schedules, and reporting in a user-friendly manner.</p>
                                        <p className="mb-0 text-uppercase f-13"><Link to={`/page/edutron-school-erp-transport`} className="text-primary">learn more
                                            <i className="mdi mdi-arrow-right ms-2"></i></Link></p>
                                    </div>
                                </div>
                                </Col>
                                <Col lg={4} md={6}>
                                <div className="service-box rounded mt-4 p-4">
                                    <div className="service-icon mb-3">
                                        <i className='mdi mdi-book'></i>
                                    </div>
                                    <div className="services-desc">
                                        <div className="service-title mb-2 position-relative">
                                            <h5 className="fw-normal mb-3"><Link to={`/page/edutron-school-erp-library`}  className="text-dark">
                                                Library</Link></h5>
                                        </div>
                                        <p className="text-muted mb-3 f-14">The Library module efficiently manages library books, circulation, and reporting in a user-friendly manner.</p>
                                        <p className="mb-0 text-uppercase f-13"><Link to={`/page/edutron-school-erp-library`} className="text-primary">learn more
                                            <i className="mdi mdi-arrow-right ms-2"></i></Link></p>
                                    </div>
                                </div>
                                </Col>
                                <Col lg={4} md={6}>
                                <div className="service-box rounded mt-4 p-4">
                                    <div className="service-icon mb-3">
                                        <i className='mdi mdi-bed'></i>
                                    </div>
                                    <div className="services-desc">
                                        <div className="service-title mb-2 position-relative">
                                            <h5 className="fw-normal mb-3"><Link to={`/page/edutron-school-erp-hostel`}  className="text-dark">
                                                Hostel</Link></h5>
                                        </div>
                                        <p className="text-muted mb-3 f-14">The Hostel module efficiently manages school hostel rooms, meals, attendance, and parent communication in a user-friendly manner.</p>
                                        <p className="mb-0 text-uppercase f-13"><Link to={`/page/edutron-school-erp-hostel`} className="text-primary">learn more
                                            <i className="mdi mdi-arrow-right ms-2"></i></Link></p>
                                    </div>
                                </div>
                                </Col>
                                <Col lg={4} md={6}>
                                <div className="service-box rounded mt-4 p-4">
                                    <div className="service-icon mb-3">
                                        <i className='mdi mdi-cash'></i>
                                    </div>
                                    <div className="services-desc">
                                        <div className="service-title mb-2 position-relative">
                                            <h5 className="fw-normal mb-3"><Link to={`/page/edutron-school-erp-fee`}  className="text-dark">
                                                Fee</Link></h5>
                                        </div>
                                        <p className="text-muted mb-3 f-14">The fee module in EduTron school ERP automates and streamlines the fee collection process, including tuition, miscellaneous fees, and fine management.</p>
                                        <p className="mb-0 text-uppercase f-13"><Link to={`/page/edutron-school-erp-fee`} className="text-primary">learn more
                                            <i className="mdi mdi-arrow-right ms-2"></i></Link></p>
                                    </div>
                                </div>
                                </Col>
                                <Col lg={4} md={6}>
                                <div className="service-box rounded mt-4 p-4">
                                    <div className="service-icon mb-3">
                                        <i className='mdi mdi-account'></i>
                                    </div>
                                    <div className="services-desc">
                                        <div className="service-title mb-2 position-relative">
                                            <h5 className="fw-normal mb-3"><Link to={`/page/edutron-school-erp-student`}  className="text-dark">
                                                Student</Link></h5>
                                        </div>
                                        <p className="text-muted mb-3 f-14">The Student module manages student records, admission, information, academics, and parent communication in a centralized and user-friendly manner.</p>
                                        <p className="mb-0 text-uppercase f-13"><Link to={`/page/edutron-school-erp-student`} className="text-primary">learn more
                                            <i className="mdi mdi-arrow-right ms-2"></i></Link></p>
                                    </div>
                                </div>
                                </Col>
                                <Col lg={4} md={6}>
                                <div className="service-box rounded mt-4 p-4">
                                    <div className="service-icon mb-3">
                                        <i className='mdi mdi-pen'></i>
                                    </div>
                                    <div className="services-desc">
                                        <div className="service-title mb-2 position-relative">
                                            <h5 className="fw-normal mb-3"><Link to={`/page/edutron-school-erp-exam`}  className="text-dark">
                                                Exam</Link></h5>
                                        </div>
                                        <p className="text-muted mb-3 f-14">The Exam module manages exams, schedules, results, grades, and reporting in a user-friendly manner, providing an efficient and effective process.</p>
                                        <p className="mb-0 text-uppercase f-13"><Link to={`/page/edutron-school-erp-exam`} className="text-primary">learn more
                                            <i className="mdi mdi-arrow-right ms-2"></i></Link></p>
                                    </div>
                                </div>
                                </Col>

                                <Col lg={4} md={6}>
                                <div className="service-box rounded mt-4 p-4">
                                    <div className="service-icon mb-3">
                                        <i className='mdi mdi-android'></i>
                                    </div>
                                    <div className="services-desc">
                                        <div className="service-title mb-2 position-relative">
                                            <h5 className="fw-normal mb-3"><Link to={`/page/teacher-mobile-app`}  className="text-dark">
                                                Teacher App</Link></h5>
                                        </div>
                                        <p className="text-muted mb-3 f-14">The Edutron School Teacher Mobile App is designed to help teachers manage their classroom activities and administrative tasks. With features like Notices, Circulars, Assignments, Attendance, Online Exam, Scholastic Marks Entry, Co-Scholastic Marks Entry, Grade Entry, Discipline Entry, Exam Remarks Entry, and Messages, the app makes it easy for teachers to stay connected with their students and school. Download the app today and start managing your classroom with ease.</p>
                                        <p className="mb-0 text-uppercase f-13"><Link to={`/page/teacher-mobile-app`} className="text-primary">learn more
                                            <i className="mdi mdi-arrow-right ms-2"></i></Link></p>
                                    </div>
                                </div>
                                </Col>
                                <Col lg={4} md={6}>
                                <div className="service-box rounded mt-4 p-4">
                                    <div className="service-icon mb-3">
                                        <i className='mdi mdi-android'></i>
                                    </div>
                                    <div className="services-desc">
                                        <div className="service-title mb-2 position-relative">
                                            <h5 className="fw-normal mb-3"><Link to={`/page/student-parent-mobile-app`}  className="text-dark">
                                                Student/Parent App</Link></h5>
                                        </div>
                                        <p className="text-muted mb-3 f-14">In today's digital age, schools and universities have begun to leverage technology to enhance their teaching and management processes. One such example is the Edutron School Student app, which provides students and parents with a streamlined way to manage their school-related activities. This article will explore the features and benefits of the Edutron School Student app and how it can help students and parents stay connected with their school.</p>
                                        <p className="mb-0 text-uppercase f-13"><Link to={`/page/student-parent-mobile-app`} className="text-primary">learn more
                                            <i className="mdi mdi-arrow-right ms-2"></i></Link></p>
                                    </div>
                                </div>
                                </Col>
                    </Row>
                </Container>
            </section>
            <Contact />
        </React.Fragment>
    )
};
export default Services;

