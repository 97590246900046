import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'reactstrap';
import pricingData from "../common/data/pricing";
import Contact from './Contact';
import { Helmet } from 'react-helmet';

const Pricing = () => {      
        return (
            <React.Fragment>
                  <Helmet>
        <title>Edutron School ERP Pricing</title>
        <meta name="description" content="Edutron School offers affordable and flexible pricing plans for our ERP solutions, including fee management, student management, exam management, library management, HR and payroll management, and more. Our pricing plans are designed to meet the unique needs of schools, and we offer a range of features and support options to ensure that you get the most out of our solutions. Contact us today to learn more about our pricing plans and how we can help your school succeed." />
        <meta name="title" content="Edutron School ERP Pricing - Affordable and Flexible Pricing Plans" />
      </Helmet>
                <section className="section" id="pricing"  style={{marginTop:40}}>
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="title-heading mb-5">
                                    <h3 className="text-dark mb-1 fw-light text-uppercase">Pricing</h3>
                                    <div className="title-border-simple position-relative"></div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                        {pricingData.map((item, key) => (
                            <Col key={key} lg={3} md={6}>
                                <div className={item.isActive ? "pricing-box active mt-4" : "pricing-box mt-4"}>
                                    <div className="price bg-light position-relative p-4">
                                        {/* <div className="float-start">
                                            <h5 className="text-dark title mt-2 fw-normal f-18 mb-0">{item.title}</h5>
                                        </div> */}
                                        {/* <div className="">
                                            
                                        </div> */}
                                        <h3 className="text-dark fw-normal text-end mb-0"><sup>Rs.</sup> {item.price}</h3>
                                    </div>
                                    <div className="p-4 pricing-list">
                                        <ul className="list-unstyled mb-0">
                                        {item.isChilItem.map((items, key) => (
                                            <li key={key} className="text-muted f-14">{items.subItem}</li>                                           
                                        ))}                                            
                                        </ul>
                                    </div>
                                    <div className="ps-4 mb-4 mt-2">
                                        <Link to="/contact-us" className={item.isBtnActive ? "btn btn-outline active btn-sm" : "btn btn-outline btn-sm"}>Buy Now</Link>
                                    </div>
                                </div>
                            </Col>
                        ))}                   
                        </Row>
                    </Container>
                </section>
                <Contact />
            </React.Fragment>
        );    
};
export default Pricing;
