import axios from 'axios';
import { NotificationManager } from 'react-notifications';

const instance = axios.create({ 
   baseURL: 'https://smsmediaapi.hellopatna.com/api/',
   timeout: 80000,
   headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
      'Content-Type': 'application/json'
   },
});

instance.interceptors.response.use(function (response) {
   // Any status code that lie within the range of 2xx cause this function to trigger
   // Do something with response data
   return response;
}, function (error) {
   if (error.response) {
      if (error.response.status === 401) {
         localStorage.removeItem('access_token');
         window.location.href = '/login';
      }
      if (error.response.status === 404) {
         NotificationManager.error(error.response.data);
      }
      if (error.response.status === 400) {
         if (error.response.data.errors) {
            NotificationManager.error(JSON.stringify(error.response.data));
            console.log(JSON.stringify(error.response.data.errors));
            return Promise.reject(error);
         }
         NotificationManager.error(error.response.data);
      }
      if (error.response.status === 0 || error.code === 'ECONNABORTED') {
         NotificationManager.error(`A timeout happend on url ${error.config.url}`);
         return Promise.reject(error);
      }
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
   } else if (error.request) {
      console.log(error.request);
   } else {
      NotificationManager.error(error.message);
   }

   return Promise.reject(error);
});

export default instance