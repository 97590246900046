import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';

//import images
import features from '../assets/images/features-img/img-1.webp';
import features2 from '../assets/images/features-img/img-2.webp';
import features3 from '../assets/images/features-img/img-3.webp';

const Features = () => {     
        return (
            <React.Fragment>
                <section className="section bg-features bg-light" id="features">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="title-heading mb-5">
                                    <h3 className="text-dark mb-1 fw-light text-uppercase">Our Features</h3>
                                    <div className="title-border-simple position-relative"></div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="align-items-center">
                            <Col lg={6}>
                                <div className="features-content"> 
                                    <h4 className="fw-normal text-dark mb-3 mt-4">Attendance Management</h4>
                                    <p className="text-muted f-14">Edutron School ERP's attendance management system streamlines the process of tracking student attendance and reduces manual errors. The software allows administrators and teachers to easily mark attendance for their classes and generate reports for analysis. The attendance tracking feature provides real-time updates and helps ensure accurate record-keeping for attendance-related activities.</p>
                                     
                                    <p className="mb-0 text-uppercase f-13"><Link to="/page/edutron-school-erp-student" className="text-primary">learn more <span
                                        className="right-arrow ms-1">&#x21FE;</span></Link></p>
                                </div>
                            </Col>

                            <Col lg={6}>
                                <div className="features-img mt-32">
                                    <img src={features} alt="school epr attenance management" className="img-fluid mx-auto d-block" />
                                </div>
                            </Col>
                        </Row>

                    </Container>
                </section>
                <section className="section bg-features">
                    <Container>
                        <Row className="align-items-center">
                            <Col lg={6}>
                                <div className="features-img">
                                    <img src={features2} alt="edutron school erp parent portal" className="img-fluid mx-auto d-block" />
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="features-content mt-32"> 
                                    <h4 className="fw-normal text-dark mb-3 mt-4">Parent Portal</h4>
                                    <p className="text-muted f-14">Edutron School ERP's parent portal provides a secure and convenient platform for parents to stay updated on their child's academic progress. The portal allows parents to view grades, attendance, and other important information about their child's performance. This helps foster open communication between parents and the school and keeps everyone informed about the child's academic progress. The parent portal is accessible from any device with an internet connection and provides a convenient way for parents to stay connected to the school.</p>                                    
                                    <p className="mb-0 text-uppercase f-13"><Link to="/page/edutron-school-erp-student" className="text-primary">learn more <span
                                        className="right-arrow ms-1">&#x21FE;</span></Link></p> 
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="section bg-features bg-light">
                    <Container>
                        <Row className="align-items-center">
                            <Col lg={6}>
                                <div className="features-content"> 
                                    <h4 className="fw-normal text-dark mb-3 mt-4">Bills and Receipt</h4>
                                    <p className="text-muted f-14">Edutron School ERP's demand bill and receipt feature streamlines the process of managing student fees and expenses. The software allows administrators to generate demand bills and receipts in real-time, and provides an efficient way for parents to make payments. This feature helps eliminate manual errors, increase accountability, and improve the overall efficiency of the fee collection process. The demand bill and receipt feature provides a clear and transparent record-keeping system that makes it easier for schools to manage their finances.</p>                                  
                                    <p className="mb-0 text-uppercase f-13"><Link to="/page/edutron-school-erp-fee" className="text-primary">learn more <span
                                        className="right-arrow ms-1">&#x21FE;</span></Link></p>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="features-img mt-40">
                                    <img src={features3} alt="school erp features" className="img-fluid mx-auto d-block" />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        )    
};
export default Features;
