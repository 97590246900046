import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Scrollspy from "react-scrollspy";

import {  
  NavbarToggler,
  NavLink,
  Container,
  Collapse,
} from "reactstrap";

// Import Logo
import logo from "../assets/images/edutron_school_erp.webp";

const Navbar = (props) => {
  const [isOpenMenu, setisOpenMenu] = useState(false);
  const [navclass, setnavclass] = useState("");
 
  const toggle = () => setisOpenMenu(!isOpenMenu);

  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);
  });

  function scrollNavigation() {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > 50) {
      setnavclass("nav-sticky");
    } else {
      setnavclass("");
    }
  }
  return (
    <React.Fragment>
      <nav className={"navbar navbar-expand-lg fixed-top navbar-custom " +
      props.isDark + 
        " sticky " +
        "sticky-dark " +
        navclass
      }
        id="navbar"
      >
        <Container>
          <Link className="navbar-brand logo" to="/">
            <img
              src={logo}
              alt="edutron school software logo"
              height="60"
            />
          </Link>
          <NavbarToggler
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
            onClick={toggle}
          >
            <i className="mdi mdi-menu"></i>
          </NavbarToggler>
          <Collapse
            isOpen={isOpenMenu}
            className="navbar-collapse"
            id="navbarCollapse"
          >
            <Scrollspy 
              currentClassName="active"
              className="navbar-nav ms-auto navbar-center" 
            >
              <li className="nav-item">
                <Link className='nav-link' to="/"  onClick={() => setisOpenMenu(false)}>Home</Link>
              </li> 
              <li className="nav-item">
              <Link className='nav-link'  to="/modules"  onClick={() => setisOpenMenu(false)}>Modules</Link> 
              </li> 
              <li className="nav-item">
              <Link className='nav-link'  to="/pricing"  onClick={() => setisOpenMenu(false)}>Pricing</Link> 
              </li> 
              <li className="nav-item">
              <Link className='nav-link'  to="/blog"  onClick={() => setisOpenMenu(false)}>Blog</Link> 
              </li>
              <li className="nav-item">
              <Link className='nav-link'  to="/contact-us"  onClick={() => setisOpenMenu(false)}>Contact Us</Link> 
              </li>
              <li className="nav-item">
                <NavLink href="http://erp.edutronschool.in/" target="_blank"  onClick={() => setisOpenMenu(false)}>Login</NavLink>
              </li>
            </Scrollspy>
          </Collapse>
        </Container>
      </nav>
    </React.Fragment>
  );
}

export default Navbar;